import RequestPasswordResetForm from '~/components/forms/account/request-password-reset-form/request-password-reset-form'
import Landing from '~/components/landing/landing'

export default function RequestPasswordReset() {
  return (
    <Landing>
      <RequestPasswordResetForm />
    </Landing>
  )
}
