import { Component, createSignal } from 'solid-js'
import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import styles from '~/components/forms/styles/simple-form.module.scss'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import { requestPasswordReset } from '~/services/database/users'
import { RequestPasswordResetSchema, RequestPasswordResetFormType } from '~/components/forms/account/request-password-reset-form/request-password-reset-form.interfaces'
import { reportError } from '~/services/errors'
import { A } from '@solidjs/router'
import { translations } from '~/translations'

const RequestPasswordResetForm: Component = () => {
  const [requestSent, setRequestSent] = createSignal(false)
  const [form, { Form, Field }] = createForm<RequestPasswordResetFormType>({
    validate: valiForm(RequestPasswordResetSchema)
  })
  const onSubmit: SubmitHandler<RequestPasswordResetFormType> = async (values) => {
    const result = await requestPasswordReset({
      email: values.email
    })
    if(result.error){
      const message = `
        ${result.error.name}\n
        ${result.error.message}\n
      `
      reportError(result.error)
      alert(message)
    }else{
      setRequestSent(true)
    }
  }

  return (
    <div class={styles.container}>
      <div class={styles.title}>
        {translations().forgotPassword.title}
      </div>
      <Form onSubmit={onSubmit} class={styles.form}>
        <Field name="email">
          {(field, props) => (
            <TextField
              label={translations().login.email}
              type='email'
              placeholder={translations().login.emailPlaceholder}
              autocomplete='email'
              {...field}
              {...props}
            />
          )}
        </Field>
        <Button
          type='submit'
          style='lagoon'
          isLoading={form.submitting}
        >
          {translations().general.actions.send}
        </Button>
        {requestSent() && (
          <div class={styles.info}>
            {translations().forgotPassword.instructions}
          </div>
        )}
      </Form>
      <A href='/account/login' class={styles.link}>
        {translations().general.actions.back}
      </A>
    </div>
  )
}

export default RequestPasswordResetForm
